.list{
    margin-left: 20px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}
.heading{
    padding-bottom: 5px;
    color: #283552;
}

.heading1{
    color: #283552;
}
.box{
    background: #fff;
    border-radius: 5px;
    /* margin-bottom: 2em; */
    /* margin: 1em auto 1em; */
    box-shadow: 0px 0px 10px #cef3ef;
    border-left: 5px solid #2dccbb;
}
 /* .box::before {
     content: '';
    position: absolute;
    bottom: 7px;
    width: 0;
    height: 0;
    border-style: solid;
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #14B2B5 transparent transparent;
 } */
/* .box::after {
    content: '';
    height: 100px;
    display: block;
    background: transparent;
    position: absolute;
    left: 30%;
    border-left: 3px dashed #2835522e;
} */
/* .wrapperBox:last-child .box::after {
    content: '';
    height: none;
    display: block;
    background: transparent;
    position: absolute;
    left: 30%;
    border-left: none;
    
} */
/* .boxTopIcon{
    background: #2dcdbc;
    padding: 10px;
    width: 15%;
    border-radius: 50%;
    border: 10px solid #e4f7f9;
    margin: 1em auto -60px;
    z-index: 1;
    position: relative;
} */
.innerBox {
    display: grid;
    grid-template-columns: .2fr 1fr;
    /* align-items: center; */
    padding: 2rem;
    padding-left: 0rem;
}
.wrapperBox:hover .boxTopIcon {
    animation: flipleft .15s linear;
}
@keyframes flipleft {
    0% {
        transform: rotateY(-100deg);
    }

    100% {
        transform: rotateY(0);
    }
}

@keyframes blink {
    0% {
        box-shadow: 0px 0px 15px #2dcdbc;
    }
    50% {
        box-shadow: none;
    }
    100% {
        box-shadow: 0px 0px 15px #2dcdbc;
    }
}

@-webkit-keyframes blink {
    0% {
        box-shadow: 0px 0px 15px #2dcdbc;
    }
    50% {
        box-shadow: 0 0 0;
    }
    100% {
        box-shadow: 0px 0px 15px #2dcdbc;
    }
}

.iconSize{
    width: 35px;
}
.boxContent h4{
 margin-bottom: 0;
}
.boxContent p {
    margin-top: 5px;
    line-height: 26px;
}
.numbers{
    font-size: 60px;
    margin: 0;
    color: #cef3ef;
    font-weight: bold;
    text-align: center;
}



.timelineContainer {
    margin: auto;
    display: block;
    position: relative;
}

.timelineContainer ul.tl {
    margin: 20px 0;
    padding: 0;
    display: inline-block;

}

.timelineContainer ul.tl li {
    list-style: none;
    margin: auto;
    min-height: 50px;
    border-left: 1px dashed #2dcdbc;
    padding: 0 0 15px 30px;
    position: relative;
    display: flex;
    flex-direction: row;
}

.timelineContainer ul.tl li:last-child {
    border-left: 0;
}

.timelineContainer ul.tl li .itemIcon {
    position: absolute;
    left: -45px;
    top: 0;
    content: " ";
    border: 8px solid rgb(45 205 188 / 45%);
    -webkit-animation: blink 3.0s linear infinite;
    -moz-animation: blink 3.0s linear infinite;
    -ms-animation: blink 3.0s linear infinite;
    -o-animation: blink 3.0s linear infinite;
    animation: blink 3.0s linear infinite;
    border-radius: 500%;
    background: #fff;
    height: 90px;
    width: 90px;
    padding: 10px;
}

.timelineContainer ul.tl li:hover::before {
    border-color: #2dcdbc;
    transition: all 1000ms ease-in-out;
}

ul.tl li .itemText {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 3rem;
}
ul.tl li .itemText h4{
    /* color: #283552;
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 20px; */
    margin-bottom: 0;
    height: 3rem;
    margin-inline: calc(1.5rem * -1);
    text-align: center;
    background-color: #cef3ef;
    color: #283552;
    font-size: 1.25rem;
    font-weight: 700;
    display: grid;
    place-content: center;
    position: relative;
    border-radius: calc(3rem / 2) 0 0 calc(3rem / 2);
}
ul.tl li .itemText h4::before {
    content: "";
    width: 1.5rem;
    aspect-ratio: 1;
    background: #2dcdbc;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
    position: absolute;
    top: 100%;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
}
ul.tl li .itemPara {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
}
.accordionHeader a{
 background-color: #fff;
}
.accordionHeader{
    line-height: auto;
}

.hrLine {
    font-size: 24px !important;
    color: #fff;
}
.section_title{
    width: 220px;
}
.section_title h1{
    margin-bottom: 5px;
}
.headingIcon{
width: 24px;
}

.hrLines {
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
}

.hrLines::before {
    content: " ";
    height: 2px;
    width: 92px;
    background: #2dcdbc;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
}

.hrLines::after {
    content: " ";
    height: 2px;
    width: 92px;
    background: #2dcdbc;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
}
.deedHeading h4{
    color: #283552;
    margin-bottom: 0;

}
.deedlist {
    align-items: center;
    display: grid;
    grid-template-columns: 50px 1fr;
    /* padding: 0 2rem 1rem 1rem; */
}
.deedlist div p{
    margin-top: 0;
}
.deedlistIcon{
    color: #2dcdbc;
    text-align: center;
    font-size: 22px !important;
}
.TableWrapper{
    padding-top: 20px;
}

.tableList{
    padding-top: 20px;
    display: none;
    list-style-type: disc;
}
.clickAction:hover ~ .tableList{
    display: block !important;
}
.tableList li {
    display: inline-block;
    padding: 9px;
    border: 1px solid #2dcdbc42;
    margin: 7px;
    box-shadow: 1px 2px 6px #2dcdbc61;
}