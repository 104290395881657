.bg26567e{
    background: #283552;
}
.boxSpacing{
    padding: 20px 0;
}
.menuBelowSpace-pt{
    padding-top: 80px;
}
.howItWorksBox{
    position: fixed;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #cef3ef;
    width: 30%;
    transform: translate(0%, 8%);
    z-index: 1;
}
.howItWorks {
    padding: 20px 30px;
}
.howItWorks ul li {
    padding-bottom: 20px;
    list-style-type: none;
}
.RequestReport {
    padding: 25px;
    text-align: center;
    background: #2dcdbc3b;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #2dcdbc;
    color: #fff;
    border: 2px solid #fff;
}
.p-steps .p-highlight.p-steps-item:before{
border-top: 2px solid #2dcdbc;
}
/* .p-steps .p-steps-item:before{
    left: 44%;
}
.p-steps-item:last-child:before {
    content: none;
} */
.p-steps.active2 .p-steps-item:first-child, .p-steps.active3 .p-steps-item {
    opacity: 1;
}
.p-steps.active2 .p-steps-item:first-child::before, .p-steps.active3 .p-steps-item::before{
border-top: 2px solid #2dcdbc;
}
.p-steps.active2 .p-steps-item:first-child .p-steps-number, .p-steps.active3 .p-steps-item .p-steps-number {
    background: #2dcdbc;
    color: #fff;
    border: 2px solid #fff;
}
/* .formInputSpace .field {
    padding-top: 12px;
} */
.formInputSpace .field {
    margin-bottom: 1.5rem;
}
 .field input{
     background: #e8f5ff;
     border-radius: 0;
     padding: 15px 10px;
     border-color: #e8f5ff;
 }

 /* steps flow */
.flexbox {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: center;
}

.flexbox .flex {
    width: 18%;
    display: flex;
    flex-direction: column;
    padding: 3%;
    justify-content: center;
}

.flexbox .flexbottom {
    border-bottom: 6px solid #2dcdbc;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    border-bottom: 20px solid #283552;
}

.flexbox .flextop {
    border-top: 5px solid #2dcdbc;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-top: 20px solid #283552;
    position: relative;
}

.flexbox .flextop:before {
    content: '';
    width: 1px;
    height: 170px;
    background: #2dcdbc;
    position: absolute;
    left: 0;
    /* border-radius: 64px; */
}

.flexbox .flextop:after {
    content: '';
    width: 1px;
    height: 170px;
    background: #2dcdbc;
    position: absolute;
    right: 0;
}

.flexbox .flexbottom {
    box-shadow: inset 0px -20px 0px #2dcdbc;
}

.flexbox .flex.flextop {
    box-shadow: inset 0px 20px 0px #2dcdbc;
}

.flexbox .stepNumber {
    width: 100%;
    text-align: center;
    /* border: 1px solid #283552; */
    border-radius: 50px 0px 50px 0px;
    background: #283552;
    color: white;
    border-right: 20px solid #2dcdbc;
    border-left: 20px solid #2dcdbc;
}
.flexbox .flextop:last-child::after {
    content: '';
    width: 1px;
    height: 170px;
    background: none;
    position: absolute;
    left: 0;
    /* border-radius: 64px; */
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none !important;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #e4f7f9 !important;
    color: #283552 !important;
}