.bg_img{
    background-image: url("../../../public/images/homeBanner/testimonialBg.jpg");
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 1;
    padding: 110px 0;
}
.bg_img::before {
    background-color: rgba(9, 30, 47, .95);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.section_title {
    padding-right: 100px;
}
.section_title h2,
.section_title h6,
.section_title p {
    color: #FFFFFF;
}
.section_title h6 {
    margin-bottom: 13px;
    font-weight: 400;
    text-transform: uppercase;
}
.section_title h2 {
    font-size: 2.25rem;
    font-weight: 600;
    margin-top: -5px;
    margin-bottom: 18px;
}
.subTitle {
    font-family: "Open Sans", sans-serif;
    color: #aaabbf;
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2.777;
    margin-bottom: 0.125rem;
    font-size: 18px;
    text-align: left;
}
.title {
    color: #263238;
    font-weight: 700;
    line-height: 1.19;
    font-size: 36px;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
}
.happyCustomer{
    margin-bottom: 2.15rem;
    font-weight: 400;
    font-size: 24px;
    text-align: left;
}
.quoteIcon{
    width: auto !important;
}
.content{
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: left;
    line-height: 28px;
}
.avaterInfo{
    text-align: left;
    /* padding-bottom: 2em; */
}
.avaterInfo p {
    font-family: "Metropolis Semi Bold";
    color: #636363;
    margin-bottom: 0;
    line-height: 1;
    font-size: 18px;
}
.avaterInfo span {
    font-family: "Metropolis Light";
    color: #636363;
    font-weight: 300;
    font-size: 15px;
}
.owlNav{
    text-align: left !important;
    font-size: 20px;
}
.owlPrev, .owlNext{
    text-decoration: underline;
}
.owlPrev:hover, .owlNext:hover {
    background: #2dcdbc !important;
}
.owlDots{
    display: block !important;
}
.owlDot.active span,
.owlDot:hover span {
    background: #fff !important;
}
.item{
    background-color: #fff;
    padding: 1.5em;
}


.hrLine{
    font-size: 24px !important;
    color: #fff;
}
.hrLines {
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
}

.hrLines::before {
    content: " ";
    height: 2px;
    width: 130px;
    background: #2dcdbc;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
}

.hrLines::after {
    content: " ";
    height: 2px;
    width: 130px;
    background: #2dcdbc;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
}