.title {
    color: #263238;
    font-weight: 700;
    line-height: 1.19;
    font-size: 36px;
    margin-top: 0;
    text-align: center;
}
.count{
    color: #263238;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 700;
    line-height: 1;
    margin: 0px;
    font-size: 60px;
    text-align: center;
}
.count:hover {
    color: #2dcdbc;
}
.subTitle{
    color: #263238;
    font-weight: 400;
    line-height: 2.083;
    margin: 0;
    font-size: 24px;
    text-align: center;
}
.icon{
    text-align: center;
    margin: 0;
}
.icon img {
   width: 30%;
}