.footer-area{
    z-index: 2;
}
.btn-outline {
    border: 2px solid #080b1a;
    border-radius: 100px;
    color: #151515;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    padding: 18px 55px;
}
.footer-top-content {
    background: #fbfbfb;
    padding: 20px 0;
}
.widget-item .widget-title {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    margin-top: -1px;
    margin-bottom: 22px;
}
.widget-list li a {
    color: #fff;
    font-weight: normal;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}
.widget-list li{
    list-style-type: none;
}
.footer-widget-area ul {
    margin: 0;
    padding: 0;
}
.copy-right-section p {
    margin: 0;
}

.copy-right-section {
    padding: 12px 0;
    background: #283552;
}
.footer-widget-area {
    background: #283552;
    border-bottom: 1px solid #e1e1e1;
    padding: 4rem 0 2rem 0;
    color: #fff;
}
.footer-social li {
    display: inline-block;
    padding-right: 20px;
    
}
.footer-social li a{
    color: #fff;;
}
.copyright-info p{
    color: #fff;
}
.footerInfo a{
    color: #fff;
}
.footerInfo a:hover {
    text-decoration: underline;
}
.mySROLogoFooter{
    width: 50%;
}