.service-area-top{
    background-position: center 32.21%;
    background-image: url("../../../public/images/homeBanner/testimonialBg.jpg");
    padding: 110px 0 130px;
    position: relative;
    z-index: 1;
}
.service-area-top:before {
    background-color: rgb(9 30 47 / 0.95);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}
.service-area-top .sub-title, .service-area-top .title{
    color: #fff;
}
.service-content-area {
    margin-top: -170px;
    position: relative;
    z-index: 1;
}
.service-item {
    margin-top: 30px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    position: relative;
    text-align: center;
    overflow: hidden;
}
.service-item .service-txt {
    background-color: #f8f9fc;
    padding: 25px 15px;
}
.service-item .service-txt h5 {
    font-weight: 600;
    margin: 0;
    font-size: 22px;
}
img {
    max-width: 100%;
}
.service-item .service-content {
    background-color: rgba(9, 30, 47, 0.95);
    color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 30px;
    position: absolute;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
}
.service-item .service-content h5 {
    color: #FFFFFF;
    font-weight: 500;
    margin-bottom: 18px;
}
.service-item .service-content h5 {
    color: #FFFFFF;
    font-weight: 500;
    margin-bottom: 18px;
    font-size: 22px;
}
.service-item .service-content h5 a {
    color: #FFFFFF;
}
.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
}
.service-item:hover {
    -webkit-box-shadow: 0 1px 5px 0 rgb(9 30 47 / 89%);
    box-shadow: 0 1px 5px 0 rgb(9 30 47 / 89%);
}
.service-item:hover .service-content {
    left: 0;
}
figure.service-thumb {
    margin: 0;
}
.highlight{
    color: #fff;
    font-weight: 400;
    border-bottom: 2px solid #2dcdbc;
}