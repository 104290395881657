.boxInfo {
    position: relative;
    padding: 20px;
    border-radius: 20px;
    color: #fff;
}
.formbox{
    background: #fff;
    border-radius: 20px;
    margin-right: 45px;
}
.formboxSpacing {
    padding: 20px 30px;
}
.vh100{
    height: 100vh;
}
.plr20{
    padding: 0 20px;
}
.borderRadius12{
    border-radius: 12px;
}
.parentDiv .stepList ul{
    display: block;
    padding: 2em 0;
}
.parentDiv .stepList ul li{
    padding-bottom: 20px;
    margin-left: 5em;
    justify-content: left;
}
.parentDiv .stepList ul li p{
    display: flex;
    align-items: center;
}
.stepsNumber{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2dcdbc;
    border: 2px solid #2dcdbc;
    min-width: 2.5rem;
    height: 2.5rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
    margin-right: 1em;
}
.textTransform{
    text-transform: uppercase;
}
.stepsTitle{
    color: #fff;
}
.stepsTitle span:first-child{
    color: #ffffffa1;
}
.activeStep .stepsNumber{
    background-color: #2dcdbc;
    color: #283552;
}
.disabled .stepsNumber{
color: #cbcbcb;
    border: 2px solid #cbcbcb;
}
/* .parentDiv .stepList ul li a{
    display: flex;
    flex-direction: unset;
    background: none !important;
}

.parentDiv .stepList ul li:before{
 border-top: none !important;
}
.parentDiv .stepList ul li a span{
    margin-right: 20px;
}
.parentDiv .stepList ul li a span:last-child{
    color: #fff !important;
} */