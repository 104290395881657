.careersSection .col-6 {
    width: 50%;
    padding: 60px 25px 100px;
    border-width: 2px;
    border-style: solid;
    border-color: #e6e6e6;
    box-sizing: border-box;
}
.careersSection .title {
    font-size: 40px;
    line-height: 50px;
    margin: 0;
    color: #283552;
}
.careersSection .p {
    font-size: 19px;
    line-height: 28px;
    margin: 20px 0;
}
.p-button:focus {
    box-shadow: none;
}
.applyNow {
    background: none;
    color: #000;
    font-weight: BOLD;
    border: none;
    padding: 10px 0px;
    border-radius: 0;
}
.applyNow::after {
    content: "";
    display: block;
    height: 3px;
    width: 10px;
    background: #000;
    transition: width 0.7s ease, background-color 0.5s ease;
    position: absolute;
    bottom: 0px;
}
.p-button.applyNow:hover {
    background: #ffffff;
    color: #283552;
}
.p-button.applyNow:hover::after {
    width: 100%;
    background: #283552;
}