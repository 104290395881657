.single-service-list p i {
    margin-right: 15px;
}


.appo_Heading {
    padding-bottom: 5px;
    color: #283552;
}
.service_ParentHeading{
    color: #283552;
}
.section_title {
    width: 220px;
}

.section_title h1 {
    margin-bottom: 5px;
}
.hrLines {
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
}

.hrLines::before {
    content: " ";
    height: 2px;
    width: 92px;
    background: #2dcdbc;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
}

.hrLines::after {
    content: " ";
    height: 2px;
    width: 92px;
    background: #2dcdbc;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
}
.headingIcon {
    width: 24px;
}
.listHeading h4 {
    color: #283552;
    margin: 0;

}

.deedlist {
    align-items: center;
    display: grid;
    grid-template-columns: 50px 1fr;
    padding: 0 2rem 1rem 0;
}

.deedlist div p {
    margin-top: 0;
}

.deedlistIcon {
    color: #2dcdbc;
    text-align: center;
    font-size: 22px !important;
}
.accordionHeader a {
    background-color: #fff;
}

.accordionHeader {
    line-height: auto;
}

.appoList {
    margin-left: 20px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    color: #283552;
}
.appoList p{
    margin: 0;
}
.p-float-label input:focus~label,
.p-float-label input:-webkit-autofill~label,
.p-float-label input.p-filled~label, 
.p-multiselect label,
.p-multiselect:focus~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label {
    top: 0.7rem !important;
}
.p-float-label input:focus,
.p-multiselect:focus{
    box-shadow: none !important;
}
.p-float-label input, .p-multiselect{
    background: #fff !important;
    border-radius: 0;
    padding: 15px 10px 5px 10px !important;
    border-color: #2dcdbc !important;
    border-radius: 6px !important;
}
.p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none !important;
}
.darkBtn {
    background: #283552 !important;
    border-color: #283552 !important;
    
}
.darkBtn:enabled:hover,
.darkBtn:focus {
    background: #fff !important;
    color: #283552 !important;
    border-color: #283552 !important;
    box-shadow: none !important;
}
.lightBtn {
    background: #fff !important;
    border-color: #2dcdbc !important;
    color: #283552 !important;
}

.lightBtn:enabled:hover,
.lightBtn:focus {
    background: #2dcdbc !important;
    color: #283552 !important;
    border-color: #2dcdbc !important;
    box-shadow: none !important;
}
.lightBlueBtn {
    background: #2dcdbc !important;
    border-color: #2dcdbc !important;
    color: #283552 !important;
}

.lightBlueBtn:enabled:hover,
.lightBlueBtn:focus {
    background: #fff !important;
    color: #283552 !important;
    border-color: #2dcdbc !important;
    box-shadow: none !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    color: #283552 !important;
    background: #e4f7f9 !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #283552 !important;
    background: #e4f7f9 !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #2dcdbc !important;
    background: #2dcdbc !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #2dcdbc !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
}
.fontColor2dcdbc {
    color: #2dcdbc;
}
.cursorPointer{
    cursor: pointer;
}