.formSectionBg{
    background: #fbfbfb;
}
.title{
    font-size: 2.25rem;
    font-weight: 600;
    margin: 0;
}
.pr8{
    padding-right: 8rem !important;
}
.topField{
    margin: 0 !important;
}
.topField input {
    background: #fff !important;
    border-radius: 0;
    padding: 15px 10px 5px 10px !important;
    border-color: #fff !important;
    border-radius: 6px !important;
}
.floatLabel input:focus~label,
.floatLabel input:-webkit-autofill~label,
.floatLabel input.p-filled~label
{
    top: 0.7rem !important;
}
.topField input:focus,
.Dropdown,
.Dropdown:focus {
    box-shadow: none !important;
    border-color: #fff !important;
}
.card{
    background: #2dcdbc;
    padding: 2rem;
}
.submitButton{
    background: #283552 !important;
    border-color: #283552 !important;
}
.submitButton:enabled:hover, .submitButton:focus {
    background: #fff !important;
    color: #283552 !important;
    border-color: #283552 !important;
}