.bg-fixed {
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}
.shadow.dark::after {
    background: #000000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.shadow {
    position: relative;
    z-index: 1;
}
.breadcrumb-area{
    background-image: url("../../public/images/homeBanner/homebanner.jpg");
    /* margin-top: 5rem; */
}
.breadcrumb-area h1 {
    display: block;
    font-weight: 600;
    margin: 0;
    color: #fff;
    text-transform: capitalize;
}
.breadcrumb-area~.navbar {
    position: relative;
}
