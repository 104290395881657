.contact-address {
    padding: 70px;
    background: #2dcdbc;
    border-radius: 6px;
}
.address-list .title {
    font-weight: 600;
    color: #fff;
    line-height: 1.25;
    text-decoration: underline;
    margin-bottom: 25px;
    font-size: 24px;
}
ul {
    padding: 0;
    margin: 0;
}
.address-list .phone-number,
.address-list .mailto, .address-list p {
    color: #fff;
}
.address-list ul li{
    list-style-type: none;
}