.title-section .sub-title {
    font-family: "Open Sans", sans-serif;
    color: #aaabbf;
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 2.777;
    margin-bottom: 0.125rem;
    font-size: 18px;
}
.text-center {
    text-align: center !important;
}
.title-section .title {
    color: #263238;
    font-weight: 700;
    line-height: 1.19;
    font-size: 36px;
    margin-bottom: 0;
    margin-top: 0;
}
.text-primary {
    --bs-text-opacity: 1;
    color: #59e3a7 !important;
}
/* .title-section .btn-dark {
    border: 1px solid;
    background: #283552;
    padding: 12px 40px;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
} */
.title-section .btn-dark {
    background: linear-gradient(to left, #2dcdbc 50%, #283552 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: #2dcdbc;
    font-weight: 600;
    padding: 12px 40px;
    border-radius: 0;
}
.title-section .btn-dark:enabled:hover {
    background-position: left bottom;
    color: #fff;
    background: linear-gradient(to left, #2dcdbc 50%, #283552 50%);
    transition: background-position 0.5s ease-out;
    border: 1px solid #2dcdbc;
    background-size: 200% 100%;
}

.title-section .btn-dark:focus {
    box-shadow: 0 0 0 1px transparent;
}
.section-pt {
    padding-top: 70px;
}
.section-pb {
    padding-bottom: 70px;
}
.position-relative {
    position: relative !important;
}
.card-shape {
    position: absolute;
    display: block;
    width: 170px;
    height: 170px;
    border-radius: 50%;
}
.card-shape-light {
    background: #a6f3eb;
    top: -100%;
    right: -100%;
    z-index: 1;
    -webkit-transition: ease-in-out 0.5s;
    transition: ease-in-out 0.5s;
}
.card-shape-dark {
    top: -100%;
    right: -100%;
    background: #46e3d3;
    z-index: 2;
    -webkit-transition: ease-in-out 0.8s;
    transition: ease-in-out 0.8s;
}
.featureCard {
    background: #f3f3f3;
    box-shadow: none;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.featureCard .serviceIcon{
    text-align: center;
}
.featureCard .title {
    color: #181c51;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1.25;
    font-size: 24px;
    text-align: center;
}
p:last-child {
    margin-bottom: 0;
}
.featureCard .title a {
    color: inherit;
}
a {
    text-decoration: none;
}
.featureCard:hover,
.featureCard.active {
    background-color: #d8fffb !important;
    -webkit-box-shadow: 0px 16px 27px 0px rgb(0 0 0 / 8%);
    box-shadow: 0px 16px 27px 0px rgb(0 0 0 / 8%);
}
.featureCard:hover .card-shape-light,
.featureCard.active .card-shape-light {
    top: -50px;
    right: -50px;
}
.featureCard:hover .card-shape-dark,
.featureCard.active .card-shape-dark {
    top: -65px;
    right: -55px;
}
.centerdivPadding .col-4:nth-child(2) .featureCard{
margin-top: 5rem;
}
.itemCenter{
    align-items: center;
}
.itemCenter>[class*=col]{
    padding: 0;
}
.bgf1f8ff{
    background-color: #f1f8ff;
}
.mySROway_para{
 padding: 10px 10px 10px 45px;
 margin: 0;
 color: #fff;
} 
.color2dcdbc{
    background-color: #2dcdbc;
}
.color283552{
    background-color: #283552;
}
.leftBorderRadius{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.rightBorderRadius {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
.mySROway_icon{
    width: 35%;
    padding: 5px 8px;
}
.topBorderRadius {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
}
.bottomBorderRadius {
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
}
.mySROway_heading{
    font-size: 26px;
    padding: 10px 10px 7px 0px;
    margin-left: 45px;
    color: #283552;
    border-bottom: 3px solid #2dcdbc;
    display: unset;
}
span.mySROway_space {
    height: 100px;
    width: 100%;
    display: block;
}