.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #283552 !important;
    background: #e6fefc !important;
}
.fontColor283552 {
    color: #283552;
}
.bge4f7f9{
    background: #e4f7f9;
}
.smoothButton {
    background: linear-gradient(to left, #2dcdbc 50%, #283552 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border: 1px solid #2dcdbc;  
    font-weight: 600;
    padding: 12px 40px;
    border-radius: 0;
    font-size: 16px;
    cursor: pointer;
}
.smoothButton:hover {
    background-position: left bottom;
    color: #fff;
    background: linear-gradient(to left, #2dcdbc 50%, #283552 50%);
    transition: background-position 0.5s ease-out;
    border: 1px solid #2dcdbc;
    background-size: 200% 100%;
}