.whoIm label {
    height: 70px;
    width: 70px;
    border: 6px solid #18f98d;
    margin: auto;
    border-radius: 10px;
    position: relative;
    color: #18f98d;
    transition: 0.5s;
    position: relative;
    cursor: pointer;
}

.whoIm input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
}
.whoIm input[type="radio"]:checked + label {
    background-color: #18f98d;
    color: #ffffff;
    box-shadow: 0 15px 45px rgb(24, 249, 141, 0.2);
}
.p-fluid .itemIncDec.p-inputnumber-buttons-horizontal{
 width: auto;
 margin-left: auto;
}
.p-fluid .itemIncDec.p-inputnumber-buttons-horizontal .p-inputnumber-input{
    width: 40px;
    background: transparent;
}
.align-item-center{
    align-items: center;
}
.m-auto{
    margin: auto;
}
.width-50{
    width: 50%;
}
.p-fluid .itemIncDec.hideDecrIput.p-inputnumber-buttons-horizontal .p-inputnumber-input{
 display: none;
}
.p-fluid .itemIncDec.hideDecrIput.p-inputnumber-buttons-horizontal .decrementButtonClassName {
    display: none;
}
.parentFieldset.p-fieldset .p-fieldset-content{
    padding-top: 0;
    padding-bottom: 0;
}
.justifyContentSpace {
    justify-content: space-between;
}
.bgGray{
    background: #f2f3f8;
}
.h-100{
    height: 100%;
}
.rightPreviewBox{
    border-radius: 10px;
}
.previewAgre{
    background: #fff;
    margin-top: 24px;
    align-items: flex-start;
    border-radius: 10px;
    padding: 16px;
    cursor: pointer;
}
span.highlightContent {
    color: #283552;
    background: #ffe7da;
    padding: 2px 13px;
    border-radius: 6px;
}