.formSectionBg{
    background: #fbfbfb;
}
.title{
    font-size: 2.25rem;
    font-weight: 600;
    margin: 0;
}
.pr8{
    padding-right: 8rem !important;
}
.topField{
    margin: 0 !important;
}
.topField input {
    background: #fff !important;
    border-radius: 0;
    padding: 15px 10px 5px 10px !important;
    border-color: #2dcdbc !important;
    border-radius: 6px !important;
}
.floatLabel input:focus~label,
.floatLabel input:-webkit-autofill~label,
.floatLabel input.p-filled~label
{
    top: 0.7rem !important;
}
.topField input:focus,
.Dropdown,
.Dropdown:focus {
    box-shadow: none !important;
    border-color: #2dcdbc !important;
}
.card{
    /* background: #2dcdbc; */
    padding: 2rem;
}
.submitButton{
    background: #283552 !important;
    border-color: #283552 !important;
}
.submitButton:enabled:hover, .submitButton:focus {
    background: #fff !important;
    color: #283552 !important;
    border-color: #283552 !important;
}

/* left Design */

.color283552{
    color: #283552;
}

.AppointmentForm{
 padding: 2.5rem 1rem 0 1rem;   
}
.formLeftSection{
    display: grid;
    grid-template-columns: 50px 1fr;
    align-items: center;
    padding: 0 2rem 1rem 1rem;
}
.borderRight{
    border-right: 1px dashed #2dcdbc;
}
.heading{
    text-decoration: underline;
}
.LeftIcon{
    width: 35px;
}
.subHeading{
    margin-bottom: 0;
}