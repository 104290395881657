.sideBar{
    background: #283552;
    height: 100vh;
}
.menuLabel{
    margin: 0;
    color: #bbb2b2;
    text-transform: uppercase;
    font-size: 13px;
}
.menuList {
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.navItem {
    line-height: 29px;
    margin-right: 1rem;
    padding: 0.5em 0px;
}
.navItem a{
    color: #fff;
}