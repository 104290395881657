.loginPage {
    height: 100vh;
    background: #ccc;
}
.height100{
    height: 100%;
}
.loginCard {
    background: #fff;
    padding: 40px 30px;
    width: 28%;
    box-shadow: 0px 0px 10px #a5a5a5;
    border-radius: 5px;
}
.heading{
    font-size: 25px;
    margin-top: 0;
}
.spaceBottom{
    padding-bottom: 16px;
}