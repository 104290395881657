.checkbox {
  appearance: none;
  height: 55px;
  width: 100px;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(37, 145, 251, 0.98) 0.1%,
    rgb(38 86 126) 99.8%
  ) !important;
  background-size: 360% 100% !important;
  border-radius: 4px !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.checkbox:after {
  content: attr(data-name);
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-family: "Inter", sans-serif;
  color: #3f3f3f;
  z-index: 99;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.checkbox:before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0px;
  height: 200%;
  width: 200%;
  background: #e9e9e9;
  z-index: 0;
  transition: all 0.2s linear;
  transform: scale(0.6) translate(-50%, -50%);
}
.checkbox:hover:before,
.checkbox:focus-visible:before {
  background: #cacaca;
  top: -4%;
  left: 20%;
}
.checkbox:checked:before {
  top: -100%;
}
.checkbox:checked:after {
  color: white;
}
.checkbox:checked {
  box-shadow: 0px 4px 10px -6px black;
}

.checkbox:focus-visible {
  outline: none;
  box-shadow: 0px 0px 0px 5px #480f5d;
}