.bannerBg{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../public/images/homeBanner/homebanner.jpg");
    background-size: cover;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    animation: shrink 10s infinite alternate;
}
@keyframes shrink {
    0% {
    background-size: 100% 100%;
    }

    100% {
    background-size: 110% 110%;
    }
}
@keyframes grow {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-50%, -50%);
        /* The image width */
    }
}
.item {
    width: 100%;
    align-items: center;
    display: grid;
    height: 100%;
    text-align: center;
}
.heading {
    font-size: 60px;
    line-height: 70px;
    margin: 20px 0;
    font-weight: 700;
    color: #fff;
    padding-bottom: 50px;
}
.view_more {
    border-radius: 5px;
    background: #283552;
    padding: 12px 40px;
    text-decoration: none;
    color: #fff;
    margin-right: 10px;
    border: 1px solid transparent;
}
.view_more:hover {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}
.contact_us {
    border-radius: 5px;
    border: solid 1px #fff;
    color: #fff;
    padding: 12px 40px;
    background: none;
}
.contact_us:hover {
    background: #283552;
    border: 1px solid transparent;
    color: #fff;
}

 .subTitle {
     color: #263238;
     font-weight: 700;
     line-height: 1.19;
     font-size: 36px;
     margin-bottom: 0;
     margin-top: 0;
     text-align: center;
 }
 .ptb1{
    padding: 1.5em 0 0.5em 0;
 }
.btn_dark {
     background: linear-gradient(to left, #2dcdbc 50%, #283552 50%);
     background-size: 200% 100%;
     background-position: right bottom;
     transition: background-position 0.5s ease-out;
     color: #fff;
     border-color: #2dcdbc;
     font-weight: 600;
     padding: 12px 40px;
     border-radius: 0;
 }
 .btn_dark:hover {
     background-position: left bottom;
     color: #fff;
     background: linear-gradient(to left, #2dcdbc 50%, #283552 50%);
     transition: background-position 0.5s ease-out;
     border: 1px solid #2dcdbc;
     background-size: 200% 100%;
 }