.p-accordion .p-accordion-header .p-accordion-header-link {
    color: #283552 !important;
    background: #fff !important;
    border: none !important;
    border-bottom: 1px solid #dee2e6 !important;
}
.faqTabs .p-accordion-tab {
    margin-bottom: 15px;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}
.p-accordion .p-accordion-content {
    border: none !important;
}
.p-accordion .p-accordion-content p{
    border: none !important;
    margin-top: 0 !important;
}
.p-accordion-header-text{
    line-height: unset !important;
}